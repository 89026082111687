import React, { useEffect, useState } from 'react';
import { getAuth, signInAnonymously } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import app from"./firebase" 
import { doc, getDoc } from "firebase/firestore";

// Define the interface for the items in the array
interface CoinByCreator {
    id: number;
    mc: string;
    name:string;
    image: string;  // Assuming mc is a string, adjust the type as necessary
}
interface CoinByCreator2 {
    id: number;
    mc: string;
    name:string;
    image: string;
    number: number  // Assuming mc is a string, adjust the type as necessary
}

const Category: React.FC = () => {
    const db = getFirestore(app);
    const url = window.location.href;
    const categoryName = url.split('/').pop(); // This gets the last segment of the URL
const [marketCap, setMarketCap] = useState("")
const [coinsIndexed, setCoinsIndexed] = useState("")
const [categoryDescription, setCategoryDescription] = useState("")

const [coinsByCreator, setCoinsByCreator] = useState<CoinByCreator[]>([]);
const [coinsByCreator2, setCoinsByCreator2] = useState<CoinByCreator2[]>([]);


    useEffect(() => {
        const auth = getAuth();
        signInAnonymously(auth)
        .then(() => {
            // Signed in..
            console.log(auth.currentUser?.uid);
            getFirestoreDoc()
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(errorCode, errorMessage);
        });
    }, []); // Empty dependency array means this effect runs once on mount
async function getFirestoreDoc(){
    const docRef = doc(db, "categories", categoryName+"");
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setCategoryDescription(docSnap.data()["description"])
      setMarketCap(formatUSD(docSnap.data()["total_market_cap"]))
      setCoinsIndexed(docSnap.data()["coins_indexed"]+"")
      getCreatorsCoinsByMarketCap(docSnap.data())
      getCreatorsCoinsByMarketCap2(docSnap.data())
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
    
}

function getCreatorsCoinsByMarketCap(doc:any ){
   let arr = []
for (var i=1; i<=100; i++){
    try {
        if (doc["coin_by_mc"+i]== undefined)
            {
                break
            }
        //coin_by_mc7
        arr.push(doc["coin_by_mc"+i])
        console.log(doc["coin_by_mc"+i])
        

    }
    catch(ee){

    }
}
setCoinsByCreator(arr);
}

//creator_by_mc
function getCreatorsCoinsByMarketCap2(doc:any ){
    let arr = []
 for (var i=1; i<=100; i++){
     try {
         //coin_by_mc7
         console.log(i)
         if (doc["creator_by_mc"+i]== undefined)
            {
                break
            }
         arr.push(doc["creator_by_mc"+i])
         
         console.log(doc["creator_by_mc"+i])
         
 
     }
     catch(ee){
 
     }
 }
 setCoinsByCreator2(arr);
 }
const formatUSD = (amount: number): string => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // You can adjust the minimumFractionDigits and maximumFractionDigits as needed
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  }

    return (
        <div>
            <h1><strong>{categoryName} </strong>Market Cap: <strong className='money'>{marketCap}</strong></h1>
            <h2>Coins indexed: {coinsIndexed} </h2>
            <div className='info-text'><p>{categoryDescription}</p></div>
<br></br>
            <h2>Top coins by Market Cap:</h2>
            <ul className='ul_coins'>
    {coinsByCreator.map((item, index) => (
        <a href={`https://pump.fun/${item.id}`}>
            <li key={item.id}>{index+1+" - "}
                <img style={{height: "33px"}} src={item.image} alt={item.name} />
                <strong>{item.name} - </strong>
                Market Cap: <span className='money'>{formatUSD(parseInt(item.mc))}</span>
                {/* Optionally, you can display the index here if needed */}
                {/* <span>Index: {index}</span> */}
            </li>
            <hr />
        </a>
    ))}
</ul>

<h2>Top coins by Creator Cap:</h2>
            <ul className='ul_coins'>
    {coinsByCreator2.map((item, index) => (
        <a href={`https://pump.fun/profile/${item.id}`}>
            <li key={item.id}>{index+1+" - "}
                <img style={{height: "33px"}} src={item.image} alt={item.name} />
                <strong>{item.id} - </strong>
                Market Cap: <span className='money'>{formatUSD(parseInt(item.mc))}</span> <span>🪙X {item.number}</span>
                {/* Optionally, you can display the index here if needed */}
                {/* <span>Index: {index}</span> */}
            </li>
            <hr />
        </a>
    ))}
</ul>
        </div>
    );
};

export default Category;
