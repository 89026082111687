import { WalletAdapterNetwork, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
//import * as borsh from '@project-serum/borsh';
import '../src/css/bootstrap.css'
import app from './firebase'
import { Routes, Route, Link } from "react-router-dom";
import './QueenCoinCard.css'; // Importing the CSS file for styling
import { collection, getDocs } from "firebase/firestore";
import { getFirestore, doc, getDoc } from "firebase/firestore";

import  { useEffect } from 'react';
import allCNFTs from './allcNFTs.json';

import bs58 from 'bs58';
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import Category from "./Category"

import {
    GlowWalletAdapter,
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter,

} from '@solana/wallet-adapter-wallets';

import { clusterApiUrl, Transaction, TransactionInstruction, SystemProgram, Keypair, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import React, { FC, ReactNode, useMemo, useCallback, useState } from 'react';

import { actions, utils, programs, NodeWallet, Connection} from '@metaplex/js'; 

interface DatacNFT {
  cNFTs: string[];
}

const BN = require("bn.js");

require('./App.css');
require('@solana/wallet-adapter-react-ui/styles.css');
let thelamports = 0;
let theWallet = "9m5kFDqgpf7Ckzbox91RYcADqcmvxW4MmuNvroD5H2r9"
function getWallet(){

    
}
// Define the type for the data you expect to send
interface PostData {
 
    wallet: string;
  }
/**
* The state of a greeting account managed by the hello world program
*/


  
  /**
   * The expected size of each greeting account.
   */
 
const App: FC = () => {

  let auth = getAuth(app)

/**
 * The public key of the account we are saying hello to
 */
let greetedPubkey: PublicKey;


 /**
* Borsh schema definition for greeting accounts
*/

 /**
  * The expected size of each greeting account.
  */








// Function to perform a POST request

  






    return (
        <Context>
            <Content />
        </Context>
    );
};


export default App;

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [
            new LedgerWalletAdapter(),
            new PhantomWalletAdapter(),
            new GlowWalletAdapter(),
            new SolflareWalletAdapter({ network }),
            new TorusWalletAdapter(),
        ],
        [network]
    );



    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );

  

};

const Content: FC = () => {
    const [signature, setSignature] = useState<string | null>(null);
    const [publicAddress, setPublicAddress] = useState<string | null>(null);
    const wallet = useWallet();
    const [cNFTs, setCNFTs] = useState<any[]>([])
    const [catDocuments, setCatDocuments] = useState<any[]>([]);
    const [officialCNFTs, setOfficialCNFTs] = useState([]);
    const [officialAllCNFTs,setOfficialAllCNFTs] = useState<string[]>([])
    const db = getFirestore(app);
    const [validcNFTsI, setValidCNFTsI]= useState(0)
    //let [wallet, setWallet] = useState("");
    let [counterI, setCounterI] = useState(1)
    let [blockchainCounter, setBlockchainCounter] = useState("")

    // const { connection } = useConnection();
    const connection = new Connection(clusterApiUrl("devnet"))
    const { publicKey, sendTransaction } = useWallet();
    const postData = async (url: string, data: PostData): Promise<void> => {
        try {
          const response = await fetch(url, {
            method: 'POST', // Method type
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data) // Convert the TypeScript object into a JSON string
          });
      
          // Check if the request was successful
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          // You can process the response further if needed
          const result = await response.json();
          setCNFTs(result)
          let counterValidI= 0
          const officialAllCNFTs2: DatacNFT = allCNFTs;

          for (var i =0; i<result.length; i++){
            //console.log("cNFTs: "+ result[i].id)
            for (var e=0; e<officialAllCNFTs2.cNFTs.length; e++){
// console.log(officialAllCNFTs[e]+"")
              if(result[i].id==officialAllCNFTs2.cNFTs[e]){
                console.log("valid")
counterValidI+=1;
              }
            }
          }

        
               
          setValidCNFTsI(counterValidI)
          console.log('Success:', result);
        } catch (error) {
          console.error('Error:', error);
        }
      };
      
      // Example usage:
      
 useEffect(() => {
  const auth = getAuth();
  signInAnonymously(auth)
  .then(() => {
      // Signed in..
      console.log(auth.currentUser?.uid);
      getFirestoreDoc()
      getFirestorecNFTsDoc()
       
  })
  .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
  });
}, []); // Empty dependency
    
    const signMessage = useCallback(async () => {
        try {
            if (!wallet.connected) {
                alert('Wallet not connected');
                return;
            }
           /*  if (!wallet.signMessage) {
                alert('Please sign message!');
                return;
            } */

          /*   const message = new TextEncoder().encode('Please sign this message to confirm your identity.');
            const signatureArrayBuffer = await wallet.signMessage(message);
            const signatureBase58 = bs58.encode(Buffer.from(signatureArrayBuffer));
            setSignature(signatureBase58);

            const walletPublicKey: PublicKey = wallet.publicKey!;
            setPublicAddress(walletPublicKey.toString());

            console.log(`Signature: ${signatureBase58}`);
            console.log(`Public Address: ${walletPublicKey.toString()}`); */

            const apiUrl = 'https://us-central1-asc-2-97b30.cloudfunctions.net/getcNFTs';
  

            const exampleData = {
                wallet:  wallet.publicKey+"",
              };
        
            // Call the function
            await postData(apiUrl, exampleData);
        } catch (error) {
            console.error('Signing error:', error);
           // alert('Failed to sign message!');
        }
    },  [wallet]);

    async function getFirestoreDoc() {
      const querySnapshot = await getDocs(collection(db, "meta_tags"));
      const docsArray: any= [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        docsArray.push({ id: doc.id, ...doc.data() });
      });
      setCatDocuments(docsArray); // Updating state with the fetched documents
    }


    async function getFirestorecNFTsDoc() {

      const docRef = doc(db, "cNFTs", "Official");
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        
        console.log("Document data:", docSnap.data());
        setOfficialCNFTs(docSnap.data().collections)
        console.log(docSnap.data().collections)

      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    }
    async function getFirestorecNFTsAllDoc() {

      const docRef = doc(db, "cNFTs", "All");
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        
        console.log("Document data:", docSnap.data());
        setOfficialAllCNFTs(docSnap.data().cNFTs)
        console.log(docSnap.data().cNFTs)
        

      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    }

    return (
       

        <div className="App">
                <div className="navbar">
        <div className="navbar-inner ">
          <a id="title" className="brand" href="#">Ascenddance.dev + Pump Gems </a>
          <ul className="nav">


          </ul>
          <ul className="nav pull-right">
          <li><a href="#">You own: <strong style={{color:"green"}}>{validcNFTsI}</strong> Valid cNFTs</a></li>

                      <li><a href="#">Telegram</a></li>
                      <li><a href="#">X</a></li>

                      <li className="divider-vertical"></li>
                      <li><WalletMultiButton /></li>

                    </ul>
        </div>
      </div>
        <br></br>
        <button onClick={signMessage} className='btn'>Check my valid cNFTs  </button>

      <button disabled className='btn'>Mint (coming soon) </button>
<button disabled  >Request Airdrop (comming soon)</button>
<h2 className='whiteH'>Official NFT collections (Buy more here 👇)</h2>
{officialCNFTs.map((item) => (


<div key={item["collection_name"]} className="rowContainer">
<a href={item["tensor_trade"]} ><img src={item["image"]} alt={item["collection_name"]} className="rowImage" /></a>
<div className="rowContent">
  <h3 className="rowTitle">{item["collection_name"]}</h3>
  <a href={item["tensor_trade"]} className="rowLink">Tensor.trade</a>
  <a href={item["magic_eden"]} className="rowLink">Magic Eden</a>

</div>
</div>

            ))}
<div className='queenCoin'>
<h2 className='whiteH'>Queen👑 Coin of the Gems</h2>

<a href='https://dexscreener.com/solana/HmbVKwp9UNHqxixYnSCgMAuhqGpTFV3Kai9snXgbdHpG'><div className="queen-coin-card">

      <img src="https://pump.mypinata.cloud/ipfs/QmXzPkN4W55GuTnHfVY2jpVEoH72EbkDcwgCudb9FcbxNR" alt="Trutle" className="coin-image"/>
      <div className="coin-info">
        <h2 className="coin-title">Trutle</h2>
        <p className="coin-description">A coin with beautiful art, strong roadmap,tokenomics, and commmunity</p>
      </div>
    </div>
    </a>
    </div>
{wallet.connected?<p className='whiteH'>Total cNFTs: {cNFTs.length}</p>:<p className='whiteH'>Please connect wallet</p>}
        <ul className='whiteH'>
          
        </ul>

        <div>

        <h1 className='whiteH'>Reports of Top Solana Meme Coins on Pump.fun to understand the market (Business Intelligence)</h1>

        <div className="grid-container">
        {catDocuments.map((doc) => (
     
        <div key={doc.id} className='grid-item'>

<a href={doc.OG_URL}><div className="queen-coin-card">

      <img src={doc.OG_IMAGE} alt="Trutle" className="coin-image"/>
      <div className="coin-info">
        <h2 className="coin-title">{doc.OG_TITLE}</h2>
        <p className="coin-description">{doc.OG_DESCRIPTION}</p>
      </div>
    </div>
    </a>
    </div>



      ))}
      </div>
    </div>

<h1 className='whiteH'>PUMP GEMS: Pump.fun Tools to Discover Gems, Trade and Earn rewards</h1>
<ul className='whiteH'>
    <li>✅Fair play, fair launch</li>
    <li>✅Claim tokens for reading about Solana #memecoins</li>
    <li>✅Tools for Trading</li>
<Routes>
    <Route path="/category/*" element={<Category />} /> 

    </Routes>

</ul>
<h2 className='whiteH'>Tokenomics & WhitePaper (coming soon)</h2>

        </div>
    );
};
